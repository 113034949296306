<template>
    <div class="container">
        <CommonBlock class="common-block" title="Голосування" :breadcrumbs="[{id: 1, name: 'Позиція партії', link: '/position' }, {id: 2, name: 'Голосування'}]">
            <div class="img">
                <img v-if="isDeputy" src="@/assets/images/voting_deputy.png" alt="image">
                <img v-else src="@/assets/images/voting_partiec.png" alt="image">
            </div>
        </CommonBlock>
        <div class="content">
            <div class="active-voting">
                Активних голосувань: {{list.count}}
            </div>
            <div class="my-voting-count">За Вашою участю: {{ list.user_count }}</div>
            <div class="voting">
                <VotingCard v-for="voting in list.items" :voting="voting" :key="voting.id"/>
            </div>
        </div>
    </div>
</template>

<script>
import CommonBlock from "../../components/CommonBlock";
import VotingCard from "../../components/voting/VotingCard"
import VotingService from "../../services/VotingService";

export default {
    name: "Voting",
    components:{
        CommonBlock,
        VotingCard
    },
    data(){
        return {
            list: {},
            isDeputy: this.$store.getters.identity.is_deputy,
        }
    },
    methods: {
        async getList(){
            let resp = await VotingService.getVotingList()
            this.list = resp.data
        }
    },
    created() {
        this.getList()
    }
}
</script>

<style scoped lang="scss">
    .voting{
        //display: flex;
        //width: 100%;
        //flex-wrap: wrap;
        //justify-content: space-between;
        column-count: 2;
        column-gap: 10px;
        border-top: 1px solid #EDEDED;
        padding-top: 21px;
        @media (max-width: 768px) {
            column-count: 1;
        }
    }
    .container{
        display: flex;
        width: 100%;
        @media (max-width: 992px) {
            flex-direction: column;
        }
        .common-block{
            width: 26.7%;
            position: fixed;
            @media (max-width: 992px) {
                width: 100%;
                position: static;
            }
        }
        .content{
            width: 100%;
            padding: 60px 40px;
            min-height: 100vh;
            background-color: #f5f5f5;
            margin-left: 26.7%;
            @media (max-width: 992px) {
                margin-left: 0;
            }
            .active-voting{
                font-size: 24px;
                font-weight: 500;
                color: #1a2744;
                margin-bottom: 5px;
            }
            .my-voting-count{
                font-size: 18px;
                font-weight: 500;
                color: #1a2744;
                margin-bottom: 21px;
            }
        }
    }

    ::v-deep{
        .common-block{
            @media (max-width: 992px) {
                min-height: 25vh;
            }
            .body{
                .title{
                    //border-bottom: 1px solid blue;
                }
            }
            .slot{
                @media (max-width: 992px) {
                    display: none;
                }
                .img{
                    img{
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            footer{
                .btn-back_icon{
                    background-color: #ffc900;
                }
            }

        }
    }

</style>
