<template>
    <div class="voting-item">
        <div class="title">{{voting.name}}</div>
        <div class="voted-count">Голосів {{voting.votingUserAnswersCount}}</div>
        <div class="line"></div>
        <router-link :to="/voting/ + voting.id" v-if="!voting.userAnswer">
            <button class="btn" type="button">Голосувати</button>
        </router-link>
        <div v-else-if="voting.userAnswer">
            <div class="answer">Моя відповідь: <span>{{voting.userAnswer.name}}</span></div>
            <router-link :to="/voting-detail/ + voting.id">
                <button class="btn btn-blue" type="button">Детальніше</button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "votingCard",
    props: {
        voting: {
            type: Object,
            require: true
        }
    }
}
</script>

<style scoped lang="scss">
    .voting-item{
        padding: 40px 40px 20px;
        border-radius: 10px;
        background-color: #fff;
        margin-bottom: 10px;
        width: 100%;
        float: left;
        page-break-inside: avoid;
        break-inside: avoid;
        .title{
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
            color: #1a2744;
        }
        .voted-count{
            border-radius: 8px;
            background-color: #f5f5f5;
            font-size: 16px;
            font-weight: 500;
            color: #1a2744;
            display: inline-block;
            padding: 4px 15px;
            margin-top: 20px;
        }
        .line{
            width: 100%;
            height: 1px;
            margin: 20px 0;
            background-color: #f5f5f5;
        }
        .btn{
            width: 220px;
            height: 50px;
            padding: 12px 0 14px;
            border-radius: 10px;
            box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
            background-color: #ffc900;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
            color: #fff;
        }
        .btn-blue{
            background-color: #1a2744;
            margin-top: 20px;
            box-shadow: 0 24px 24px -14px rgba(26, 39, 68, 0.35);
        }
        .answer{
            span{
                font-weight: bold;
                color: #1a2744;
            }
        }
    }
</style>
